@import '../../../node_modules/antd/lib/style/index';

body {
   color: @heading-color;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 16px;
  cursor: pointer;
  transition: color .3s;
}

.trigger:hover {
  color: #4482ff;
}

.ant-layout-sider-collapsed .anticon {
  font-size: 16px;
}

.ant-layout-sider-collapsed .nav-text {
  display: none;
}

.ant-layout {
  background: #ffffff;
  overflow: auto;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #4482ff;
  border-color: #4482ff;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #4482ff;
}

.ant-menu-vertical {
  border-right: 1px solid #e5e8f7;
}
.ant-menu-vertical .ant-menu-item {
  border-right: 1px solid #e5e8f7;
}

@primary-color: #ff3a3a;@border-color-base: #DEDFF0;@border-radius-base: 2px;