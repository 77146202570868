@import "../node_modules/antd/lib/style/index";
@import (less) "~animate.css/animate.min.css";

// import "~animate.css/animate.min.css";

@font-face {
  font-family: "MyFont";
  src: local("MyFont"), url(./icons/RWAHoneydew-Regular.otf) format("opentype");
}

.ant-popover-inner-content {
  padding: 0px !important;
  // background-color: ${palette("text", 0)};
}
.ant-popover-arrow {
  display: none !important;
}

@primary-color: #ff3a3a;@border-color-base: #DEDFF0;@border-radius-base: 2px;